<template>
  <div style="display: flex; flex-direction: column; height: calc(100vh - 180px)">
    <div style="display: flex; flex-wrap: wrap">
      <div class="condition-item">
        <label class="fn-14">任务标题</label>
        <el-input
          v-model="searchTitle"
          placeholder="请输入任务标题"
          autocomplete="off"
          size="small"
          style="width: 130px"
        >
        </el-input>
      </div>

      <div class="condition-item">
        <label class="fn-14">学校</label>
        <el-select
          clearable
          filterable
          v-model="searchSchoolId"
          remote
          reserve-keyword
          placeholder="请输入学校名或代码检索"
          :remote-method="remoteMethod"
          :loading="loading"
          autocomplete="off"
          size="small"
        >
          <el-option
            v-for="item in schools"
            :key="item.id"
            :label="item.schoolFilterName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>

      <div class="condition-item">
        <label class="fn-14">学生类型</label>
        <el-select
          style="width: 130px"
          clearable
          v-model="studentType"
          placeholder="全部"
          autocomplete="off"
          size="small"
        >
          <el-option
            v-for="item in studentList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="condition-item">
        <label class="fn-14">采集时间</label>
        <el-date-picker
          v-model="searchCreateDate"
          type="date"
          placeholder="选择开始时间"
          autocomplete="off"
          size="small"
          style="width: 160px"
        >
        </el-date-picker>
        <label class="fn-14" style="padding-left: 10px">-</label>
        <el-date-picker
          v-model="searchCreateDateEnd"
          type="date"
          placeholder="选择结束时间"
          autocomplete="off"
          size="small"
          style="width: 160px"
        >
        </el-date-picker>
      </div>
      <div class="condition-item">
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="handleSearch"
          size="small"
          v-if="searchButton"
          >查询
        </el-button>
        <el-button
          type="primary"
          icon="el-icon-plus"
          @click="addTask"
          size="small"
          v-if="addtAskButton"
          >新建对比任务
        </el-button>
      </div>
    </div>

    <div style="margin-bottom: 15px; flex: 1; overflow: hidden">
      <el-table :data="TaskList" v-loading="tableLoading" stripe border height="100%">
        <template #empty>
          <p>{{ tableLoading == true ? "数据加载中" : "暂无数据" }}</p>
        </template>

        <el-table-column prop="taskInfoNames" label="任务名称" width="450">
          <template slot-scope="scope">
            <p v-for="item in scope.row.taskInfoNames">
              {{ item }}
            </p>
          </template>
        </el-table-column>

        <el-table-column
          prop="comparisonTypeName"
          align="center"
          label="对比内容"
          width="280"
        >
        </el-table-column>
        <el-table-column align="center" label="对比进度" width="350">
          <template slot-scope="scope">
            <p
              :style="
                !scope.row.comparisonProgress.waitCount
                  ? 'color: #fb3f4e'
                  : 'color:#14cf63'
              "
            >
              &nbsp;&nbsp;&nbsp;&nbsp;比对完成:{{
                scope.row.comparisonProgress.finishCount
              }}&nbsp;&nbsp;&nbsp;&nbsp;<span
                v-show="!scope.row.comparisonProgress.waitCount"
                >待比对 :{{ scope.row.comparisonProgress.waitCount }}</span
              >
              <br />
              不支持比对:{{ scope.row.comparisonProgress.failCount }}
            </p>
          </template>
        </el-table-column>
        <el-table-column label="导入状态" width="100" align="center">
          <template slot-scope="scope">
            <span v-show="scope.row.uploadStatus == 0">新增任务</span>
            <span v-show="scope.row.uploadStatus == 1">上传中</span>
            <span v-show="scope.row.uploadStatus == 2">上传完成</span>
            <span v-show="scope.row.uploadStatus == 3">比对完成</span>
          </template>
        </el-table-column>
        <el-table-column label="对比结果" width="350" align="center">
          <template slot-scope="scope">
            <span>总人数:{{ scope.row.comparisonResult.totalCount }}</span>
            &nbsp;&nbsp;
            <span>通过:{{ scope.row.comparisonResult.passCount }}</span>
            &nbsp;&nbsp;
            <span>不通过:{{ scope.row.comparisonResult.noPassCount }}</span>
            &nbsp;&nbsp;
            <span>待比对:{{ scope.row.comparisonResult.notCount }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="asynReason" label="操作" width="350" align="center">
          <template slot-scope="scope">
            <el-button
              size="small"
              v-if="uploaderButton"
              type="text"
              @click="uploader(scope.row.id)"
              >上传录取/入学照</el-button
            >
            <el-button
              type="text"
              v-if="comparisonButton"
              size="small"
              @click="handleSee(scope.row.id)"
              >查看对比结果</el-button
            >
            <el-button
              type="text"
              size="small"
              v-if="stuinfoButton"
              @click="handleInfo(scope.row.id)"
              >学生信息</el-button
            >
            <el-button
              size="small"
              type="text"
              v-if="downloadButton"
              @click.native.prevent="handleDownload(scope.row.id)"
              >下载对比报告</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: right">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 新增弹窗 -->
    <div>
      <el-dialog
        title="选择订单"
        :visible.sync="dialogVisible"
        width="1200px"
        class="ERER"
      >
        <el-form label-width="150px" ref="XPcontrastForm" :model="XPcontrastForm">
          <el-row>
            <el-col :span="24">
              <el-form-item label="选择校拍任务：">
                <el-button
                  type="primary"
                  icon="el-icon-plus"
                  @click="chooseTask"
                  size="small"
                  >选择校拍任务
                </el-button>
              </el-form-item></el-col
            >
          </el-row>
          <el-row>
            <el-col :span="24">
              <!-- 弹窗列表盒子 -->
              <div
                style="
                  margin: 0 auto;
                  width: 1000px;
                  padding-left: 90px;
                  padding-bottom: 20px;
                  max-height: 280px;
                  overflow-y: auto;
                "
              >
                <el-table
                  border
                  style="width: 100%; text-align: center"
                  :data="addList"
                  :header-cell-style="{
                    background: '#f5f7fa',
                    color: '#606266',
                  }"
                  v-loading="tableAddLoading"
                >
                  <template #empty>
                    <p>
                      {{ tableAddLoading == true ? "数据加载中" : "暂无数据" }}
                    </p>
                  </template>

                  <el-table-column prop="title" label="任务名称" width="350">
                  </el-table-column>
                  <el-table-column
                    align="center"
                    prop="shotSubject"
                    label="学生类型"
                    width="120"
                  >
                    <template slot-scope="scope">
                      <span v-show="scope.row.shotSubject == 1">毕业生</span>
                      <span v-show="scope.row.shotSubject == 0">新生</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    align="center"
                    prop="count"
                    label="采集人数"
                    width="100"
                  >
                  </el-table-column>
                  <el-table-column
                    align="center"
                    width="260"
                    prop="beginTime,endTime"
                    label="采集时间"
                  >
                    <template slot-scope="scope">
                      {{ formDateFormat(scope.row.beginTime) }} -
                      {{ formDateFormat(scope.row.endTime) }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="asynReason" label="操作" align="center">
                    <template slot-scope="scope">
                      <el-button
                        size="small"
                        type="danger"
                        @click.native.prevent="handleDelete(scope.$index, addList)"
                        >删除</el-button
                      >
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="选择对比内容：">
                <el-checkbox-group v-model="contentTypeList">
                  <el-checkbox
                    v-for="item in comparisonList"
                    :label="item.value"
                    :key="item.value"
                    >{{ item.label }}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div slot="footer" style="text-align: center">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button
            type="primary"
            @click="handlePreservation"
            :loading="PreservationLoading"
            >保 存</el-button
          >
        </div>
      </el-dialog>
    </div>
    <div>
      <el-dialog
        title="上传照片"
        :visible.sync="uploaderVisible"
        width="450px"
        class="ERER"
      >
        <div
          style="max-height: 500px; overflow: hidden; overflow-y: auto; width: 100%"
          v-loading="FileUpdateLoading"
          element-loading-text="文件正在上传中.."
          element-loading-spinner="el-icon-loading"
        >
          <el-form ref="uploaderForm" :model="uploaderForm" label-width="180px">
            <el-row>
              <el-col :span="24">
                <el-form-item label="上传照片:">
                  <el-upload
                    class="upload-demo"
                    ref="uploadImport"
                    :multiple="false"
                    action=""
                    :limit="1"
                    :on-change="handleUploadChange"
                    :file-list="[]"
                    accept=".zip,.rar"
                    :auto-upload="false"
                    :show-file-list="false"
                  >
                    <el-button slot="trigger" type="primary">选取文件 </el-button>
                  </el-upload></el-form-item
                >
              </el-col>
            </el-row>
          </el-form>
        </div>
      </el-dialog>
    </div>
    <!-- 选择校拍列表弹窗 -->
    <div>
      <el-dialog
        title="选择订单"
        :visible.sync="chooseVisible"
        width="1200px"
        class="ERER"
      >
        <!-- 搜索部分 -->
        <div style="display: flex; flex-wrap: wrap">
          <div class="condition-item">
            <label class="fn-14">校拍任务名称</label>
            <el-input
              clearable
              placeholder="请输入校拍任务名称"
              autocomplete="off"
              size="small"
              v-model="searchChooseTitle"
            ></el-input>
          </div>
          <div class="condition-item">
            <label class="fn-14">采集时间</label>
            <el-date-picker
              v-model="searchChooseBeginTime"
              type="date"
              placeholder="选择采集时间"
              autocomplete="off"
              size="small"
              style="width: 140px"
            >
            </el-date-picker>
            <label class="fn-14" style="padding-left: 10px">-</label>
            <el-date-picker
              v-model="searchChooseEndTime"
              type="date"
              placeholder="选择采集时间"
              autocomplete="off"
              size="small"
              style="width: 140px"
            >
            </el-date-picker>
          </div>
          <div class="condition-item">
            <label class="fn-14">学校</label>
            <el-select
              clearable
              filterable
              v-model="searchSChoosechoolName"
              remote
              reserve-keyword
              placeholder="请输入学校名或代码检索"
              :remote-method="remoteMethod"
              :loading="loading"
              autocomplete="off"
              size="small"
            >
              <el-option
                v-for="item in schools"
                :key="item.id"
                :label="item.schoolFilterName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="condition-item">
            <label class="fn-14">摄影师</label>
            <el-input
              v-model="searchChooseChotographer"
              clearable
              placeholder="请输入摄影师"
              autocomplete="off"
              size="small"
              style="width: 150px"
            ></el-input>
          </div>

          <div class="condition-item">
            <el-button
              type="primary"
              size="small"
              style="width: 85px"
              @click="choiceSearch"
              >搜索</el-button
            >
          </div>
        </div>
        <div>
          <el-table
            border
            :data="choiceList"
            style="width: 100%; text-align: center"
            height="450"
            :header-cell-style="{ background: '#f5f7fa', color: '#606266' }"
            v-loading="chooseDataVisible"
            @selection-change="handleSelect"
          >
            <template #empty>
              <p>
                {{ chooseDataVisible == true ? "数据加载中" : "暂无数据" }}
              </p>
            </template>
            <el-table-column align="center" type="selection" width="50" label="全选">
            </el-table-column>
            <el-table-column prop="title" label="任务名称"> </el-table-column>
            <el-table-column
              align="center"
              prop="shotSubject"
              label="学生类型"
              width="150"
            >
              <template slot-scope="scope">
                <span v-show="scope.row.shotSubject == 1">毕业生</span>
                <span v-show="scope.row.shotSubject == 0">新生</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="count" label="采集人数" width="120">
            </el-table-column>

            <el-table-column
              align="center"
              prop="beginTime,endTime"
              width="260"
              label="采集时间"
            >
              <template slot-scope="scope">
                {{ formDateFormat(scope.row.beginTime) }} -
                {{ formDateFormat(scope.row.endTime) }}
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div slot="footer" class="dialog-footer">
          <el-button @click="chooseVisible = false">取 消</el-button>
          <el-button type="primary" @click="handleStorage">确 定</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 学生列表弹窗 -->
    <div>
      <el-dialog title="学生信息" :visible.sync="StuVisible" width="1200px" class="ERER">
        <!-- 搜索部分 -->
        <div style="display: flex; flex-wrap: wrap">
          <div class="condition-item">
            <label class="fn-14">订单号</label>
            <el-input
              v-model="StuOrderNo"
              clearable
              placeholder="请输入订单号"
              autocomplete="off"
              size="small"
              style="width: 200px"
            ></el-input>
          </div>
          <div class="condition-item">
            <label class="fn-14">学生姓名</label>
            <el-input
              v-model="StuStudentName"
              clearable
              placeholder="请输学生姓名"
              autocomplete="off"
              size="small"
              style="width: 130px"
            ></el-input>
          </div>
          <div class="condition-item">
            <label class="fn-14">身份证号</label>
            <el-input
              v-model="StuIdNumber"
              clearable
              placeholder="请输入身份证号"
              autocomplete="off"
              size="small"
              style="width: 180px"
            ></el-input>
          </div>

          <div class="condition-item">
            <el-button type="primary" size="small" style="width: 85px" @click="StuSearch"
              >查询</el-button
            >
            <el-button
              type="success"
              size="small"
              @click="exportStu"
              :loading="exportStuLodaing"
              >导出对比失败学生</el-button
            >
          </div>
        </div>
        <div>
          <el-table
            border
            :data="StuList"
            style="width: 100%; text-align: center"
            height="450"
            :header-cell-style="{ background: '#f5f7fa', color: '#606266' }"
            v-loading="StuDataVisible"
          >
            <template #empty>
              <p>
                {{ StuDataVisible == true ? "数据加载中" : "暂无数据" }}
              </p>
            </template>

            <el-table-column prop="orderNo" label="订单号" width="240" fixed>
            </el-table-column>
            <el-table-column
              align="center"
              prop="studentName"
              label="姓名"
              width="150"
              fixed
            >
              <template slot-scope="scope">
                <span>{{ execDecrypt(scope.row.studentName, "name") }}</span></template
              >
            </el-table-column>
            <el-table-column
              align="center"
              prop="mobileNumber"
              label="手机号"
              width="180"
            >
              <template slot-scope="scope">
                <span>{{ execDecrypt(scope.row.mobileNumber, "ph") }}</span></template
              >
            </el-table-column>
            <el-table-column align="center" prop="idNumber" label="身份证号" width="180">
              <template slot-scope="scope">
                <span>{{ execDecrypt(scope.row.idNumber, "Idcard") }}</span></template
              >
            </el-table-column>
            <el-table-column align="center" prop="schoolName" label="学校" width="200">
            </el-table-column>
            <el-table-column align="center" prop="studentCode" label="学号" width="180">
            </el-table-column>
            <el-table-column align="center" prop="major" label="专业" width="150">
            </el-table-column>
            <el-table-column
              align="center"
              prop="createTime"
              width="160"
              :formatter="columnDateFormat"
              label="采集时间"
            >
            </el-table-column>
            <el-table-column align="center" prop="errorMsg" label="对比结果" width="320">
            </el-table-column>
          </el-table>
        </div>
        <!-- 分页 -->
        <div style="display: flex; justify-content: center; padding-top: 10px">
          <el-pagination
            @size-change="handleSizeChangeStu"
            @current-change="handleCurrentChangeStu"
            :current-page="currentPageStu"
            style="display: flex; justify-content: center; flex-direction: row"
            :page-sizes="[10, 20, 30, 40, 50, 100, 200, 300]"
            :page-size="pageSizeStu"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalStu"
          >
          </el-pagination>
        </div>
      </el-dialog>
    </div>

    <!-- 比对报告弹窗 -->
    <div>
      <el-dialog
        title="查看比对结果"
        :visible.sync="reportVisible"
        width="1200px"
        class="ERER"
      >
        <div
          style="
            padding-bottom: 10px;
            border-bottom: 1px solid #ededed;
            margin-bottom: 20px;
          "
        >
          <el-row :gutter="18">
            <el-col :span="5">
              <div class="text-module">
                <div class="imgs" style="background: #0078ff">
                  <img src="@/assets/img/data.png" alt="" />
                </div>

                <div class="info">
                  <p>通过</p>
                  <p class="num">{{ reportStuData.passCount }}</p>
                </div>
              </div>
            </el-col>
            <el-col :span="1" class="tc">
              <el-divider direction="vertical"></el-divider>
            </el-col>
            <el-col :span="5">
              <div class="text-module">
                <div class="imgs" style="background: #ff3a30">
                  <img src="@/assets/img/data.png" alt="" />
                </div>

                <div class="info">
                  <p>未通过</p>
                  <p class="num">{{ reportStuData.noPassCount }}</p>
                </div>
              </div>
            </el-col>
            <el-col :span="1" class="tc">
              <el-divider direction="vertical"></el-divider>
            </el-col>
            <el-col :span="5">
              <div class="text-module">
                <div class="imgs" style="background: #ff9502">
                  <img src="@/assets/img/data.png" alt="" />
                </div>

                <div class="info">
                  <p>待比对</p>
                  <p class="num">{{ reportStuData.notCount }}</p>
                </div>
              </div>
            </el-col>
            <el-col :span="1" class="tc">
              <el-divider direction="vertical"></el-divider>
            </el-col>
            <el-col :span="5">
              <div class="text-module">
                <div class="imgs" style="background: #ff9502">
                  <img src="@/assets/img/data.png" alt="" />
                </div>

                <div class="info">
                  <p>总人数</p>
                  <p class="num">{{ reportStuData.totalCount }}</p>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <!-- 搜索部分 -->
        <div style="display: flex; flex-wrap: wrap; padding-bottom: 10px">
          <div class="condition-item">
            <label class="fn-14">订单号</label>
            <el-input
              v-model="ReportOrderNo"
              clearable
              placeholder="请输入订单号"
              autocomplete="off"
              size="small"
              style="width: 200px"
            ></el-input>
          </div>
          <div class="condition-item">
            <label class="fn-14">学生姓名</label>
            <el-input
              v-model="ReportStudentName"
              clearable
              placeholder="请输学生姓名"
              autocomplete="off"
              size="small"
              style="width: 130px"
            ></el-input>
          </div>
          <div class="condition-item">
            <label class="fn-14">身份证号</label>
            <el-input
              v-model="ReportIdNumber"
              clearable
              placeholder="请输入身份证号"
              autocomplete="off"
              size="small"
              style="width: 180px"
            ></el-input>
          </div>

          <div class="condition-item">
            <el-button
              type="primary"
              size="small"
              style="width: 85px"
              @click="reportSearch"
              >查询</el-button
            >
            <el-button type="success" size="small" @click="reportBatch"
              >批量下载比对报告</el-button
            >
          </div>
        </div>
        <div style="overflow: auto; height: 420px">
          <template v-for="item in reportForm">
            <div class="reportBox">
              <!-- 资料显示 -->
              <div style="width: 600px">
                <el-form ref="reportForm" label-width="80px">
                  <el-row class="rowGray">
                    <el-col class="cell" :span="4">
                      <label>姓名</label>
                    </el-col>
                    <el-col class="cell" :span="8">
                      <label>{{ item.studentName }}</label>
                    </el-col>
                    <el-col class="cell" :span="4">
                      <label>学校</label>
                    </el-col>
                    <el-col class="cell" :span="8">
                      <label>{{ item.schoolName }} </label>
                    </el-col>
                  </el-row>
                  <el-row class="rowBlank">
                    <el-col class="cell" :span="4">
                      <label>身份证号</label>
                    </el-col>
                    <el-col class="cell" :span="8">
                      <label v-text="item.idNumber"></label>
                    </el-col>
                    <el-col class="cell" :span="4">
                      <label>学历类别</label>
                    </el-col>
                    <el-col class="cell" :span="8">
                      <label v-text="item.educationType"></label>
                    </el-col>
                  </el-row>

                  <el-row class="rowGray">
                    <el-col class="cell" :span="4">
                      <label>学号</label>
                    </el-col>
                    <el-col class="cell" :span="8">
                      <label v-text="item.studentCode"></label>
                    </el-col>
                    <el-col class="cell" :span="4">
                      <label>专业</label>
                    </el-col>
                    <el-col class="cell" :span="8">
                      <label v-text="item.major"></label>
                    </el-col>
                  </el-row>

                  <el-row class="rowBlank">
                    <el-col class="cell" :span="4">
                      <label>班级</label>
                    </el-col>
                    <el-col class="cell" :span="8">
                      <label v-text="item.classes"></label>
                    </el-col>
                    <el-col class="cell" :span="4">
                      <label>考生号</label>
                    </el-col>
                    <el-col class="cell" :span="8">
                      <label v-text="item.examineeNumber"></label>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
              <!-- 照片对比 -->
              <div class="repor_img">
                <div style="position: relative">
                  <div class="desc">录取照片</div>
                  <el-image
                    :src="'data:image/png;base64,' + item.photoBase"
                    class="photo"
                    v-if="item.photoBase"
                  >
                    <div slot="placeholder" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                  <el-image :src="photoImg" class="photo" v-else>
                    <div slot="placeholder" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                  <div class="uploadPhoto">
                    <span @click="dlgImportUP(item.id)" type="text"> 重新上传照片 </span>
                  </div>
                </div>
                <div>VS</div>
                <div style="position: relative">
                  <div class="desc">入学照片</div>
                  <el-image
                    :src="'data:image/png;base64,' + item.pathCollectedPhotoBase"
                    class="photo"
                    v-if="item.pathCollectedPhotoBase"
                  >
                    <div slot="placeholder" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                  <el-image :src="entranceImg" class="photo" v-else>
                    <div slot="placeholder" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                  <div></div>
                </div>
                <div style="width: 260px; margin-left: 10px">
                  <h5>比对结果</h5>
                  <p>
                    姓名&身份证结果:<span style="margin-left: 10px; color: #8ee3a7">{{
                      item.nidResultMsg
                    }}</span>
                  </p>
                  <p>
                    采集照&人口库结果:<span style="margin-left: 10px">{{
                      item.pidResultMsg
                    }}</span>
                  </p>
                  <p>
                    导入照&人口库结果:<span style="margin-left: 10px">{{
                      item.ppResultMsg
                    }}</span>
                  </p>
                  <p>
                    导入照&采集照结果:<span style="margin-left: 10px">{{
                      item.upidResultMsg
                    }}</span>
                  </p>
                </div>
              </div>
            </div>
          </template>
        </div>
        <!-- 分页 -->
        <div style="display: flex; justify-content: center; padding-top: 10px">
          <el-pagination
            @size-change="handleSizeChangeReport"
            @current-change="handleCurrentChangeReport"
            :current-page="currentPageReport"
            style="display: flex; justify-content: center; flex-direction: row"
            :page-sizes="[2, 5, 10, 20]"
            :page-size="pageSizeReport"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalReport"
          >
          </el-pagination>
        </div>
      </el-dialog>
    </div>

    <!-- 上传照片裁剪 -->
    <div>
      <el-dialog
        append-to-body
        title="上传裁剪"
        ref="dialog"
        width="1200"
        class="img-cropper"
        :visible.sync="UPdialogVisible"
        :close-on-click-modal="false"
      >
        <div class="cropper-w" style="overflow: hidden">
          <div class="cropper" :style="{ width: '100%', height: '700px' }">
            <vueCropper
              ref="cropper"
              :img="option.img"
              :outputSize="option.size"
              :outputType="option.outputType"
              :info="option.info"
              :full="option.full"
              :canMove="option.canMove"
              :canMoveBox="option.canMoveBox"
              :original="option.original"
              :autoCrop="option.autoCrop"
              :autoCropWidth="option.autoCropWidth"
              :autoCropHeight="option.autoCropHeight"
              :fixedBox="option.fixedBox"
            ></vueCropper>
          </div>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="UPdialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="finish" :loading="finishDialog"
            >确认</el-button
          >
        </div>
      </el-dialog>
      <!--    上传学生照片-->
      <el-dialog
        title="上传学生照片"
        :visible.sync="dlgImportVisible"
        width="380px"
        center="center"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
        <el-form label-width="150px">
          <el-form-item label="上传照片：" prop="file" style="margin-bottom: 0">
            <el-row class="photo-box">
              <el-col :span="9">
                <div class="item">
                  <el-upload
                    class="upload-demo"
                    ref="uploadImport"
                    :multiple="false"
                    action=""
                    :show-file-list="false"
                    :on-change="uploadPhoto"
                    accept="image/jpeg,image/png"
                    list-type="picture"
                    :auto-upload="false"
                  >
                    <el-button type="primary"> 选取文件</el-button>
                  </el-upload>
                </div>
              </el-col>
            </el-row>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>
<style scoped>
.text-module {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 260px;
  height: 70px; */
}
.text-module div.imgs {
  width: 45px;
  height: 45px;
  border-radius: 2px;
}
.text-module div.imgs img {
  width: 100%;
}
.text-module div.info {
  margin-left: 20px;
  text-align: center;
  font-size: 14px;
  color: #999;
}
.text-module div.info p {
  font-size: 14px;
  margin: 0;
  padding: 0;
}
.text-module div.info p.num {
  padding-top: 5px;
  font-size: 18px;
  color: #333;
}
.reportBox {
  display: flex;
  justify-content: space-around;
  padding-bottom: 20px;
  align-items: center;
}
.repor_img {
  display: flex;
  width: 550px;
  align-items: center;
  justify-content: space-around;
  padding-left: 20px;
}
.rowGray .cell,
.rowBlank .cell {
  padding: 0 5px;
  border: #e8e8e8 1px solid;
  height: 40px;
  line-height: 40px;
  vertical-align: bottom;
}

.rowGray .cell {
  background: #f2f2f2;
}
.photo {
  width: 110px;
  height: 130px;
  position: relative;
  vertical-align: top;
}
.photo-box .item {
  text-align: center;
}
.uploadPhoto {
  position: absolute;
  bottom: 0px;
  width: 100%;
  text-align: center;
  line-height: 30px;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
  cursor: pointer;
}
.desc {
  text-align: center;
  width: 100%;
  line-height: 25px;
  background: rgba(0, 0, 0, 0.2);
}
</style>
<script>
import { dateFormat } from "@/utils/date";
import { getSchoolFiller } from "@/api/school";
import { exportprocess } from "@/api/student";

import {
  taskComparisonPhoto,
  comparisonTaskAdd,
  comparisonTaskList,
  comparisonTaskstudentList,
  resultTotal,
  downloadReport,
  resultList,
  uploadFile,
} from "@/api/contrast";
import { minioUpload, merge } from "@/api/order";
import { VueCropper } from "vue-cropper";
import axios from "axios";
import { getToken } from "@/utils/auth";
import SparkMD5 from "spark-md5";
import { formatPh, formatName, formatIdCard } from "@/utils/validate";
import { queryPageButton } from "@/api/permission";

const chunkSize = 5 * 1024 * 1024;

export default {
  name: "XPcontrast",

  components: {
    VueCropper,
  },
  data() {
    return {
      //学校
      schools: [],
      allSchools: [],
      searchSchoolId: null,
      loading: false,
      // 列表搜索条件
      studentList: [
        {
          value: 0,
          label: "新生",
        },
        {
          value: 1,
          label: "毕业生",
        },
      ],
      studentType: null,
      searchTitle: null,
      searchCreateDate: "",
      searchCreateDateEnd: "",
      currentPage: 1,
      pageSize: 20,
      total: 0,

      TaskList: [], //列表数据
      tableLoading: false, //列表页面加载
      Downloadloading: false, //对比报告下载加载

      // 选择订单、新增列表
      XPcontrastForm: {},
      addList: [],
      tableAddLoading: false,
      PreservationLoading: false,
      dialogVisible: false,

      // 上传录取照片
      uploaderVisible: false,
      uploaderForm: {},
      comparisonId: null,
      FileUpdateLoading: false,

      // 重传照片
      dlgImportVisible: false,
      dlgImportId: null,

      // fileForm
      choiceList: [],
      tableSelection: [],
      chooseVisible: false,
      chooseDataVisible: false,
      searchChooseTitle: null,
      searchChooseBeginTime: null,
      searchChooseEndTime: null,
      searchSChoosechoolName: null,
      searchChooseChotographer: null,
      comparisonList: [
        {
          value: 1,
          label: "姓名&身份证",
        },
        {
          value: 2,
          label: "导入照&人口库",
        },
        {
          value: 3,
          label: "采集照&人口库",
        },
        {
          value: 4,
          label: "导入照&采集照",
        },
      ],
      contentTypeList: [], //对比选择框
      //学生信息
      StuList: [],
      StuDataVisible: false,
      StuVisible: false,
      currentPageStu: 1,
      pageSizeStu: 20,
      totalStu: 0,
      StuOrderNo: null,
      StuStudentName: null,
      StuIdNumber: null,
      StuId: null,
      exportStuLodaing: false, //导出失败加载
      //比对报告
      reportForm: [],
      reportVisible: false,
      currentPageReport: 1,
      pageSizeReport: 2,
      totalReport: 0,
      photoImg: "../temp/none_img.jpg",
      entranceImg: "../temp/none_img.jpg",
      isPhoto: true,
      ReportOrderNo: null,
      ReportStudentName: null,
      ReportIdNumber: null,
      reportStuData: {}, //人数汇总

      // 裁剪
      finishDialog: false,
      UPdialogVisible: false,
      option: {
        img: "", // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 0.8, // 裁剪生成图片的质量
        outputType: "jpeg", // 裁剪生成图片的格式
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 480, // 默认生成截图框宽度
        autoCropHeight: 640, // 默认生成截图框高度
        fixedBox: true, // 固定截图框大小 不允许改变
        full: true, // 是否输出原图比例的截图
        canMoveBox: true, // 截图框能否拖动
        original: true, // 上传图片按照原始比例渲染
      },
      //权限按钮
      searchButton: false,
      addtAskButton: false,
      uploaderButton: false,
      comparisonButton: false,
      stuinfoButton: false,
      downloadButton: false,
    };
  },
  created() {
    this.queryButton();
    this.query();
    this.querySchools();
  },
  methods: {
    queryButton() {
      const data = 160;
      queryPageButton(data).then((resp) => {
        // console.log(resp.data);
        for (const button of resp.data) {
          if (button.code === "searchButton") {
            this.searchButton = true;
          }
          if (button.code === "addtAskButton") {
            this.addtAskButton = true;
          }
          if (button.code === "uploaderButton") {
            this.uploaderButton = true;
          }
          if (button.code === "comparisonButton") {
            this.comparisonButton = true;
          }
          if (button.code === "stuinfoButton") {
            this.stuinfoButton = true;
          }
          if (button.code === "downloadButton") {
            this.downloadButton = true;
          }
        }
      });
    },
    querySchools() {
      getSchoolFiller().then((resp) => {
        this.allSchools = resp.data;
        this.schools = resp.data;
      });
    },
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.schools = this.allSchools.filter((item) => {
            if (typeof item != undefined) {
              return item.schoolFilterName.indexOf(query) > -1;
            }
          });
        }, 200);
      } else {
        this.schools = [];
      }
    },
    // 对比列表
    query() {
      const data = {
        taskTitle: this.searchTitle,
        stime: this.searchCreateDate,
        etime: this.searchCreateDateEnd,
        schoolId: this.searchSchoolId,
        shotSubject: this.studentType,
      };
      this.tableLoading = true;
      comparisonTaskList(data, this.currentPage, this.pageSize).then((resp) => {
        if (resp.code == 0) {
          this.TaskList = resp.data.content;
          this.total = resp.data.totalElements;
        } else {
          this.tableLoading = false;
        }
      });
    },
    // 校拍列表
    queryTask() {
      const data = {
        title: this.searchChooseTitle,
        beginTime: this.searchChooseBeginTime,
        endTime: this.searchChooseEndTime,
        schoolName: this.searchSChoosechoolName,
        photographer: this.searchChooseChotographer,
      };
      this.chooseDataVisible = true;
      taskComparisonPhoto(data, this.currentPage, this.pageSize).then((resp) => {
        if (resp.code == 0) {
          this.choiceList = resp.data;
          this.totalAdd = resp.data.totalElements;
        } else {
          this.chooseDataVisible = false;
        }
      });
    },
    //新建对比任务
    addTask() {
      this.addList = [];
      this.XPcontrastForm = {};
      this.contentTypeList = [];
      this.dialogVisible = true;
    },
    //对比结果
    handleSee(id) {
      this.reportForm = [];
      this.photoImg = "../temp/none_img.jpg";
      this.entranceImg = "../temp/none_img.jpg";
      this.ReportStuId = id;

      (this.ReportOrderNo = null),
        (this.ReportStudentName = null),
        (this.ReportIdNumber = null),
        resultTotal(this.ReportStuId).then((resp) => {
          if (resp.code == 0) {
            let datas = resp.data;
            this.reportStuData = datas;
            this.queryReportStu(this.ReportStuId);
          } else {
            this.$notify({
              title: "警告",
              message: resp.message,
              type: "warning",
            });
          }
        });
    },
    //比对学生信息
    queryReportStu(id) {
      this.reportVisible = true;
      let data = {
        id: id,
        orderNo: this.ReportOrderNo,
        studentName: this.ReportStudentName,
        idNumber: this.ReportIdNumber,
      };
      resultList(data, this.currentPageReport, this.pageSizeReport).then((resp) => {
        if (resp.code == 0) {
          this.reportForm = resp.data.content;
          this.totalReport = resp.data.totalElements;
        } else {
          this.$notify({
            title: "警告",
            message: resp.data.message,
            type: "warning",
          });
        }
        this.reportStuVisible = false;
      });
    },
    //学生信息
    queryStu(id) {
      let postData = {
        id: id,
        orderNo: this.StuOrderNo,
        studentName: this.StuStudentName,
        idNumber: this.StuIdNumber,
      };
      this.StuDataVisible = true;
      comparisonTaskstudentList(postData, this.currentPageStu, this.pageSizeStu).then(
        (resp) => {
          if (resp.code == 0) {
            this.StuList = resp.data.content;
            this.totalStu = resp.data.totalElements;
          } else {
            this.$notify({
              title: "警告",
              message: resp.message,
              type: "warning",
            });
          }
          this.StuDataVisible = false;
        }
      );
    },

    handleInfo(id) {
      this.StuVisible = true;
      this.StuId = id;
      this.StuOrderNo = null;
      this.StuStudentName = null;
      this.StuIdNumber = null;
      this.StuList = [];
      this.queryStu(this.StuId);
    },
    // 下载对比报告
    handleDownload(id) {
      this.Downloadloading = this.$loading({
        lock: true,
        text: "文件下载中....",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      downloadReport(id).then((resp) => {
        if (resp.code == 0) {
          this.taskExportprocess(resp.data.taskNo);
        } else {
          this.$notify({
            title: "警告",
            message: resp.message,
            type: "warning",
          });
        }
        // loading.close();
      });
    },
    /**
     * 查询任务进度
     */
    taskExportprocess(taskNo = "") {
      let that = this;
      exportprocess(taskNo).then((res) => {
        if (res.data.state == 0) {
          setTimeout(() => {
            that.taskExportprocess(taskNo);
          }, 500);
        } else if (res.data.state == 1) {
          that.$notify({
            title: "成功",
            message: "打包下载成功",
            type: "success",
          });
          if (res.data.msg == "打包成功") {
            this.Downloadloading.close();
          }
          setTimeout(() => {
            let urlFile = res.data.url;
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = urlFile;
            let fileName = Date.parse(new Date()) + ".rar";
            link.download = fileName;
            // console.log(link);
            link.click();
            window.URL.revokeObjectURL(link.href);
          }, 1000);
          this.queryLIst();
        } else {
          this.$notify({
            title: "警告",
            message: res.data.msg[0],
            type: "warning",
          });
          this.Downloadloading.close();
          this.queryLIst();
        }
      });
    },
    // 上传列表照片
    uploader(id) {
      this.uploaderVisible = true;
      this.FileUpdateLoading = false;
      this.comparisonId = id;
      this.UploadFile = null;
    },

    // 选择校拍
    handleSelect(val) {
      this.tableSelection = val;
    },
    //新增保存
    handlePreservation() {
      const json = JSON.parse(JSON.stringify(this.addList));
      if (json === null || json.length === 0) {
        this.$notify({
          title: "警告",
          message: "请先选择校拍任务",
          type: "warning",
        });
        return;
      }
      let postList = [];
      let onelist = {};
      json.map((item) => {
        onelist = {
          id: item.id,
          collectType: item.collectType,
        };
        postList.push(onelist);
      });
      if (this.contentTypeList.length) {
        let postData = {
          comparisonType: this.contentTypeList,
          comparisonTasks: postList,
        };

        this.PreservationLoading = true;
        comparisonTaskAdd(postData)
          .then((resp) => {
            if (resp.code == 0) {
              this.$notify.success({
                title: "温馨提示",
                message: resp.message,
              });
            } else {
              this.$notify({
                title: "警告",
                message: "选择的任务不能为空",
                type: "warning",
              });
            }
            this.contentTypeList = [];
            this.PreservationLoading = false;
            this.dialogVisible = false;
            this.query();
          })
          .catch((error) => {
            this.PreservationLoading = false;
            this.query();
          });
      } else {
        this.$notify({
          title: "警告",
          message: "请选择比对内容",
          type: "warning",
        });
      }
    },

    // 选择校拍任务
    chooseTask() {
      this.chooseVisible = true;
      (this.searchChooseTitle = null),
        (this.searchChooseBeginTime = null),
        (this.searchChooseEndTime = null),
        (this.searchSChoosechoolName = null),
        (this.searchChooseChotographer = null),
        this.queryTask();
    },
    // 选择校拍任务---搜索
    choiceSearch() {
      this.currentPageAdd = 1;
      this.queryTask();
    },
    // 选择校拍选择确定
    handleStorage() {
      const tableSelection = JSON.parse(JSON.stringify(this.tableSelection));
      if (tableSelection === null || tableSelection.length === 0) {
        this.$notify({
          title: "警告",
          message: "选择的任务不能为空",
          type: "warning",
        });
      } else {
        tableSelection.map((item) => {
          const judge = this.addList.filter((o) => o.id == item.id); //判断是否重复条件
          if (!judge.length) {
            this.addList.push(item);
          }
          this.chooseVisible = false;
        });
      }
    },
    // 删除
    handleDelete(index, rows) {
      if (this.addList.length == 1) {
        rows.splice(index, 1);
        this.XPcontrastForm = {};
        this.contentTypeList = [];
      } else {
        rows.splice(index, 1);
      }
    },
    // 重传照片按钮
    dlgImportUP(id) {
      this.dlgImportId = id;
      this.dlgImportVisible = true;
    },
    // 重新上传照片
    uploadPhoto(data) {
      this.openCropper(data.raw);
    },
    openCropper(file) {
      var _this = this;
      this.option.img = "";
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png" ||
        file.type === "image/PNG" ||
        file.type === "image/JPG";
      if (!isJPG) {
        this.$message.error("上传图片只能为jpg或png格式");
        return;
      }
      var reader = new FileReader();
      reader.onload = (e) => {
        let data;
        if (typeof e.target.result === "object") {
          data = window.URL.createObjectURL(new Blob([e.target.result]));
        } else {
          data = e.target.result;
        }
        _this.option.img = data;
        _this.UPdialogVisible = true;
      };
      reader.readAsDataURL(file);
      // console.log(reader);
    },
    // 裁剪确认
    finish() {
      this.finishDialog = true;
      let _this = this;
      this.$refs.cropper.getCropBlob(async (data) => {
        let formData = new FormData();
        // console.log(data);
        if (data) {
          var timestamp = new Date().getTime() + ".jpg";
          const newFile = new window.File([data], timestamp);
          formData.append("file", newFile);
          formData.append("id", this.dlgImportId);
          uploadFile(formData).then((resp) => {
            if (resp.code == 0) {
              this.$notify.success({
                title: "温馨提示",
                message: resp.message,
              });
              this.queryReportStu(this.ReportStuId);
              this.finishDialog = false;
              this.UPdialogVisible = false;
              this.dlgImportVisible = false;
            }
          });
        } else {
          this.finishDialog = false;
          this.photoImg = "../temp/none_img.jpg";
          this.$message.warning("请重新上传图片");
        }
      });
      _this.$forceUpdate();
    },

    //上传录取入学照片
    async handleUploadChange(file, fileList) {
      // console.log(this.UploadFile);
      if (file.raw) {
        this.FileUpdateLoading = true;
        let fileData = file.raw;
        const fileMd5 = await this.getFileMd5(fileData);
        let i = 0;
        this.PostFile(fileData, i, fileMd5);
      }
    },

    // 分片上传文件
    PostFile(file, i, fileMd5) {
      let fileName = file.name,
        fileType = file.type,
        fileSize = file.size,
        chunkCount = Math.ceil(fileSize / chunkSize); //总片数
      if (i >= chunkCount) {
        return;
      }
      let start = i * chunkSize;
      let end = start + chunkSize;
      let packet = file.slice(start, end); //将文件进行切片
      console.log(packet);
      let form = new FormData();
      form.append("md5", fileMd5);
      form.append("file", packet); //slice方法用于切出文件的一部分
      form.append("name", fileName);
      form.append("totalSize", fileSize);
      form.append("total", chunkCount); //总片数
      form.append("index", i + 1); //当前是第几片
      minioUpload(form).then((resp) => {
        if (resp.data.status === 20001) {
          /*  表示上一块文件上传成功，继续下一次  */
          form = "";
          i++;
          this.PostFile(file, i, fileMd5);
        } else if (resp.data.status === 50000) {
          form = "";
          /*  失败后，每2秒继续传一次分片文件  */
          setInterval(function () {
            this.PostFile(file, i, fileMd5);
          }, 2000);
        } else if (resp.data.status === 20002) {
          this.merge(chunkCount, fileName, fileMd5, fileSize, fileType);
          this.$notify({
            title: "温馨提示",
            message: resp.message,
            type: "success",
          });
        } else {
          this.$notify({
            title: "温馨提示",
            message: resp.message,
            type: "success",
          });
        }
      });
    },
    // 合并分片文件
    merge(chunkCount, fileName, fileMd5, fileSize, fileType) {
      let postData = {
        shardCount: chunkCount,
        md5: fileMd5,
        fileName: fileName,
        fileType: fileType,
        fileSize: fileSize,
        id: this.comparisonId,
      };
      merge(postData).then((resp) => {
        if (resp.code == 0) {
          let uploadData = {
            filePath: resp.data.fileName,
            comparisonId: this.comparisonId,
          };
          axios({
            url: "/api/csias/sys/comparisonTask/uploadPhoto",
            method: "POST",
            dataType: "json",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + getToken(),
            },
            data: uploadData,
            timeout: 180000,
          }).then((response) => {
            if (response.status === 200) {
              this.$notify({
                title: "温馨提示",
                message: response.data.msg,
                type: "success",
              });
            } else {
              this.$notify({
                title: "警告",
                message: response.data.msg,
                type: "warning",
              });
            }
            this.uploaderVisible = false;
          }),
            (err) => {
              this.$notify.error({
                title: "错误",
                message: response.data.msg,
              });
            };
        } else {
          this.$notify({
            title: "警告",
            message: "未知错误",
            type: "warning",
          });
        }
        this.uploaderVisible = false;
      });
    },
    // md5文件转化
    getFileMd5(file, chunkCount) {
      return new Promise((resolve, reject) => {
        let blobSlice =
          File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice;
        let chunks = chunkCount;
        let currentChunk = 0;
        let spark = new SparkMD5.ArrayBuffer();
        let fileReader = new FileReader();

        fileReader.onload = function (e) {
          spark.append(e.target.result);
          currentChunk++;
          if (currentChunk < chunks) {
            loadNext();
          } else {
            let md5 = spark.end();
            resolve(md5);
          }
        };
        fileReader.onerror = function (e) {
          reject(e);
        };
        function loadNext() {
          let start = currentChunk * chunkSize;
          let end = start + chunkSize;
          if (end > file.size) {
            end = file.size;
          }
          fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
        }
        loadNext();
      });
    },
    // 学生列表查询
    StuSearch() {
      this.currentPageStu = 1;
      this.queryStu(this.StuId);
    },
    handleSizeChangeStu(val) {
      this.pageSizeStu = val;
      this.queryStu(this.StuId);
    },
    handleCurrentChangeStu(val) {
      this.currentPageStu = val;
      this.queryStu(this.StuId);
    },
    //导出对比失败
    exportStu() {
      this.exportStuLodaing = true;
      axios({
        method: "GET",
        url: "/api/csias/sys/comparisonTask/exportFail?id=" + this.StuId,
        headers: {
          Authorization: "Bearer " + getToken(),
          "Content-Type": "application/json",
        },
        responseType: "blob",
        timeout: 1200000,
      }).then(
        (response) => {
          if (response.status === 200 && response.data) {
            let blob = new Blob([response.data], {
              type: "application/octet-stream",
            });

            let fileName = Date.parse(new Date()) + ".xlsx";
            if (window.navigator.msSaveOrOpenBlob) {
              navigator.msSaveBlob(blob, fileName);
            } else {
              var link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = fileName;
              link.click();
              //释放内存
              window.URL.revokeObjectURL(link.href);
            }
            this.$notify.success({
              title: "成功",
              message: "下载文件成功！",
            });
          } else {
            this.$notify.error({
              title: "错误",
              message: "下载失败，未查询到数据",
            });
          }
          this.exportStuLodaing = false;
        },
        (err) => {
          this.exportStuLodaing = false;
          this.$notify.error({
            title: "错误",
            message: "下载失败，未查询到数据",
          });
        }
      );
    },
    handleSearch() {
      this.currentPage = 1;
      this.query();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.query();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.query();
    },

    //   比对报告搜素
    reportSearch() {
      this.currentPageReport = 1;
      this.queryReportStu(this.ReportStuId);
    },
    // 批量比对报告下载
    reportBatch() {},
    handleSizeChangeReport(val) {
      this.pageSizeReport = val;
      this.queryReportStu(this.ReportStuId);
    },
    handleCurrentChangeReport(val) {
      this.currentPageReport = val;
      this.queryReportStu(this.ReportStuId);
    },
    columnDateFormat(row, column, cellValue, index) {
      const dateValue = row[column.property];
      if (dateValue != null) {
        return dateFormat("YYYY-mm-dd", new Date(dateValue));
      }
    },
    formDateFormat(dateValue) {
      if (dateValue != null) {
        return dateFormat("YYYY-mm-dd", new Date(dateValue));
      }
    },
    // 姓名、手机号脱敏
    execDecrypt(str, mod) {
      if (!str) {
        return str;
      } else if (mod == "ph") {
        return formatPh(str);
      } else if (mod == "name") {
        return formatName(str);
      } else if (mod == "Idcard") {
        return formatIdCard(str);
      }
    },
  },
};
</script>
